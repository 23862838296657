<template>
  <div role="main" class="main">
    <section>
      <div class="registration-page-content common-page">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12 col-md-8">
                  <div class="row">
                    <div class="col-12 text-left page-heading mb-4">
                      <h3 class="m-0">live Stream Wedding Starts</h3>
                      <p class="m-0 text-white" v-if="event_info">
                        on {{ event_info.start_date_time }} 
                    {{ event_info.time_zone }}
                        <!-- on September 14<br />8:00PM<br />PST -->
                      </p>
                    </div>
                  </div>
                  <div class="custom-group mb-2">
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder="Please leave a personal message or a memory."
                      v-model="message"
                    ></textarea>
                    <!-- <VueEmoji
                      ref="emoji"
                      @input="onInput"
                      :value="myText"
                      height="100"
                      width="367px !important"
                    /> -->
                  </div>
                </div>
                <div class="col-12 col-md-4"></div>
                <div class="col-12 col-md-8 text-right">
                  <b-button
                    type="button"
                    class="btn-primary white-border"
                    @click="goNext('condolence')"
                    >Submit</b-button
                  >
                </div>
                <!-- <div class="col-12 col-md-8 text-right">
                  <b-button
                    type="button"
                    class="btn-primary white-border"
                    @click="goNext('voicemail')"
                    >Congratulate the couples voicemail</b-button
                  >
                </div> -->
                <div class="col-12 col-md-4"></div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12 col-md-2"></div>                
                <div class="col-12 col-md-7" v-if="event_info!=null">
                  <div class="c-scroll">
                    <div  v-for="(input,k) in imagesArray" :key="k" class="mb-3">
                    <img
                      :src="input.program_url"
                      class="img-fluid c-img-scoll"
                    />
                    </div>
                  </div>
                <!-- <div class="c-dashbord-carousel">
                <v-carousel v-model="imageNumber" @change="here()" >
                  <v-carousel-item
                    v-for="(item, i) in imagesArray"
                    :key="i"
                    :src="item.program_url"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                  ></v-carousel-item>
                
                  <div class="arrow-icon-wrapper mr-3 prev-icon">
                    <img src="../../assets/icons/caret-left-solid_white.svg" />
                  </div>
                  <div class="arrow-icon-wrapper ml-3 next-icon">
                    <img src="../../assets/icons/caret-right-solid_white.svg" />
                  </div>
                
              </v-carousel>
            </div> -->
                  <!-- <img
                    :src="event_info.program_url"
                    class="img-fluid pt-3"
                  /> -->
                </div>
                <div class="col-12 col-md-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="registration-page-footer">
        <div class="footer-wrapper container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-2 c-col-lg-2 d-flex justify-content-center" v-if="event_info && event_info.tel!=''">
              <div class="img-avatar-banner" @click="goNext('voicemail')">
                <img
                  src="../../assets/img/voicemail.jpg"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name">Record a congratulations message </div>
              </div>
            </div>
            <div class="col-lg-2 c-col-lg-2 d-flex justify-content-center" v-if="event_info && event_info.gift_pay_url!=''">
              <div class="img-avatar-banner" @click="goNext('donation')">
                <img
                  src="../../assets/img/DonateWithPaypal.png"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name">Gift with <span>PayPal</span></div>
              </div>
            </div>
            <div class="col-lg-2 c-col-lg-2 d-flex justify-content-center">
              <div class="img-avatar-banner">
                <img
                  src="../../assets/img/upload-photo.png"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name" @click="goNext('upload_photo')">
                  Upload a photo
                </div>
              </div>
            </div>
            <div class="col-lg-2 c-col-lg-2 d-flex justify-content-center" v-if="event_info && event_info.reminder_url!=''">
              <div class="img-avatar-banner" @click="goNext('calendar')">
                <img
                  src="../../assets/img/calendar.png"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name">Calendar reminder</div>
              </div>
            </div>
            <div class="col-lg-2 d-flex justify-content-center" v-if="event_info && event_info.gift_registry_url!=''">
              <div class="img-avatar-banner" @click="goNext('shop')">
                <img
                  src="../../assets/img/gift.png"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name">Gift Registry</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl, liveBaseUrl } from "../../constants/config";
// import VueEmoji from "emoji-vue";

export default {
  data() {
    return {
      event_id: 0,
      event_info: null,
      visitor_id: 0,
      message: "",
      event: "",
      checkLive: null,
      connection: null,
      myText: "",
      imagesArray:[]
    };
  },
  mounted() {
    this.event_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).event_id
      : 0;
    this.visitor_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).token
      : 0;

    getApiManager()
      .post(`${apiBaseUrl}/api/visitor/profile/get-info`, {
        visitor_id:this.visitor_id,
      }).then((resss) => {
        if(resss.data.error=='visitor_not_exist'){
          localStorage.removeItem("loginInfo");
          this.$router.push("/visitor/home/MTE=");
        }
    })
    getApiManager()
      .post(`${apiBaseUrl}/api/visitor/profile/get-event`, {
        event_id: this.event_id,
      })
      .then((response) => {
        let res = response.data;
        if (res.status === true) {
          this.event_info = res.detail;
          this.event_info.start_date_time = this.getFormatedDate();
          getApiManager()
          .post(`${apiBaseUrl}/api/admin/event/getuploadImagebyEventId`, {
            event_id:  this.event_id,
          })
          .then((response) => {
              this.imagesArray=response.data.detail;
              console.log("&&&&&&&&&&",this.imagesArray)
              if(this.imagesArray && this.imagesArray && this.imagesArray.length>0){
              for(var i=0;i<this.imagesArray.length;i++){
                this.imagesArray[i].program_url=`${apiBaseUrl}` + "/" + this.imagesArray[i].program_url
              }
            }
          })
          // this.event_info.program_url =
          //   `${apiBaseUrl}/` + this.event_info.program_url;
          if (res.detail.status == 1) {
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push("/visitor/wait/"+this.event_id+"?x=" + randomnumber);
          } else if (res.detail.status == 2) {
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push("/visitor/live/"+this.event_id+"?x=" + randomnumber);
          } else if (res.detail.status == 3) {
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push("/visitor/parting/"+this.event_id+"?x=" + randomnumber);
          } else {
            this.connectSocket();
          }
        } else {
          this.$notify({
            group: "foo",
            type: "warn",
            title: "Error",
            text: "Server Error",
            animation_type: "slide",
          });
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // this.checkLive = setInterval(() => {
    //   this.goLive()
    // }, 1000*30);
  },
  methods: {
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function (event) {
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        if (data.type == "change_order") {
          ref.receiveMoveLive(data.order);
        } else if (data.type == "user_info") {
          ref.connection.send(
            JSON.stringify({
              type: "my_info",
              event_id: ref.event_id,
            })
          );
        } else if (data.type == "start_count") {
          ref.receiveMoveLive(data.order);
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    receiveMoveLive(order) {
      this.connection.close();
      if (order == 1) {
        if (this.$route.fullPath != "/visitor/wait") {
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          this.$router.push("/visitor/wait/"+this.event_id+"?x=" + randomnumber);
        }
      } else if (order == 2) {
        if (this.$route.fullPath != "/visitor/live") {
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          this.$router.push("/visitor/live/"+this.event_id+"?x=" + randomnumber);
        }
      }
    },
    getFormatedDate() {
      const formateddate=new Date(this.event_info.start_date_time)
      const firstdayMonth = formateddate.getMonth() + 1;
      const firstdayDay = formateddate.getDate();
      const firstdayYear = formateddate.getFullYear();
      const time = new Date(this.event_info.start_date_time).toLocaleTimeString('en', { timeStyle: 'short', hour12: true, timeZone: 'UTC' });

      
      return ("0000" + firstdayYear.toString()).slice(-4) + "-" + ("00" + firstdayMonth.toString()).slice(-2) + "-" + ("00" + firstdayDay.toString()).slice(-2)+" "+time
      
    },
    onInput(event) {
      this.message = event.data;
    },
    clearTextarea() {
      this.$refs.emoji.clear();
    },
    goNext(page) {
      if (page == "condolence") {
        if (this.message == "") {
          this.$notify({
            group: "foo",
            type: "warn",
            title: "Waring",
            text: "Please input the message.",
            animation_type: "slide",
          });
          return;
        }
        getApiManager()
          .post(`${apiBaseUrl}/api/visitor/condolence/create`, {
            event_id: this.event_id,
            message: this.message,
            visitor_id: this.visitor_id,
          })
          .then((response) => {
            let res = response.data;
            if (res.status === true) {
              this.connection.send(
                JSON.stringify({
                  type: "get_message",
                })
              );
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/visitor/condolence/" + res.condolence_id+"/"+this.event_id+"?x=" + randomnumber)
            } else {
              this.$notify({
                group: "foo",
                type: "warn",
                title: "Error",
                text: "Server Error",
                animation_type: "slide",
              });
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (page == "upload_photo")
      { 
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/visitor/" + page+"?x=" + randomnumber);
    }
      // else if(page=="shop")
      //   window.open(this.event_info.gift_registry_url,"_blank");
      // else if (page=="donation")
      //   window.open(this.event_info.gift_pay_url,"_blank");
      // else if(page=="calendar")
      //   alert("coming soon")
      else if (page == "shop") {
        window.open(this.event_info.gift_registry_url, "_blank");
      } else if (page == "donation") {
        window.open(this.event_info.gift_pay_url, "_blank");
      } else if (page == "calendar") {
        window.open(this.event_info.reminder_url, "_blank");
      } else if (page=='voicemail') {
        window.open(this.event_info.tel,"_blank");
      } else {
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/visitor/page/" + page+"?x=" + randomnumber);
      }
    },
    goLive() {
      let ref = this;
      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/profile/get-event`, {
          event_id: this.event_id,
        })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.connection.close();
            if (res.detail.status == 1) {
              clearInterval(ref.checkLive);
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/visitor/wait/"+this.event_id+"?x=" + randomnumber);
            } else if (res.detail.status == 2) {
              clearInterval(ref.checkLive);
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/visitor/live/"+this.event_id+"?x=" + randomnumber);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    // VueEmoji,
  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
  .v-window-item {
  min-width: 200px !important;
  width: 350px !important;
  min-height: 400px !important;
  height: 200px !important;
  overflow: hidden;
  border: 5px solid #fff;
}
.banner-name {
  color: #fff;
  font-weight: 400;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 8px 30px;
  position: relative;
  margin-left: -23px;
  z-index: 1;
  cursor: pointer;
}
.c-col-lg-2{
      flex: 0 0 20% !important;
    max-width: 20% !important;

}
@media screen and (max-width: 765px) {
  .c-col-lg-2{
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.c-img-scoll {
  height: 370px;
    object-fit: cover;
}
.c-scroll{
  height: calc(100vh - 400px);
  overflow: auto;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #772803;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #772803;
}
</style>